import React from "react"
import Layout from "../../components/layoutEn"
import SEO from "../../components/layout/seo"

import MainImg from "../../img/whereSE.jpg"
import SiImg from "../../img/siEn.jpg"

function Si() {
  return (
    <Layout>
      <SEO title="M2MTech" />
      <div className="fobWrap siWrap">
        <p className="fobMainImg siMainImg">
          <img src={MainImg} alt="" />
        </p>
        <div className="sectionWrap fobSectionWrap siOverview">
          <div className="textWrap">
            <h2>Integrated Disaster Prevention System SI</h2>
            <p className="siImg">
              <img src={SiImg} alt="" />
            </p>
            <p className="bodyText">
              <b>
                Integrated interlocking control according to operation scenario
              </b>{" "}
              <br />
              -Access Control Event <br />
              -Power load alarm
              <br />
              -Emergency fire
              <br />
              <br />
              <b>Linked items</b>
              <br />
              -Facility control
              <br />
              -Power/lighting
              <br />
              -Disaster prevention <br />
              -Access control
              <br />
              -CCTV <br />
              -Building Guide
              <br />
              -Public announcing <br />
              -Elevator control <br />
              -BEMS <br />
              -FMS
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Si
